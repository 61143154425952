import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left',
    color: '#000'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 7,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    background: 'url("/images/hero.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      defaultCss: {
        backgroundColor: 'rgba(232, 238, 249, .93)'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '120px 0 80px',
            widescreen: '120px 0 80px',
            desktop: '120px 40px 80px',
            tablet: '100px 40px 60px',
            mobile: '100px 20px 40px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 6,

                        fontSize: {
                          desktop: '18px',
                          tablet: '14px',
                          mobile: '14px'
                        },
                        margin: {
                          desktop: '0 0 5px',
                          tablet: '0 0 5px',
                          mobile: '0 0 5px'
                        },
                        defaultCss: {
                          color: '#000'
                        },
                        text:
                          'An alle <u>Hausbesitzer</u>, die sich unabhängig von steigenden Strompreisen machen wollen:'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        size: 2,
                        margin: {
                          desktop: '0 100px 10px',
                          tablet: '0',
                          mobile: '0'
                        },
                        defaultCss: {
                          color: colors.primary
                        },
                        text:
                          'Spare jedes Jahr bis zu 35 % Stromkosten und produziere Deinen Strom einfach selbst – mit einer Solaranlage auf Deinem Dach'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '150px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '30px auto 10px',
                          tablet: '30px auto 0',
                          mobile: '30px auto 0'
                        }
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet',
                  defaultCss: {
                    textAlign: 'center'
                  },
                  containers: [
                    {
                      type: 'video',
                      config: {
                        url: 'https://vimeo.com/571646384',
                        previewUrl: '/videos/preview.mp4',
                        color: colors.primary
                      }
                    },
                    {
                      type: 'image',
                      config: {
                        src: '/images/top-dienstleister.jpg',
                        margin: {
                          desktop: '20px 0 0'
                        },
                        width: {
                          desktop: '200px',
                          tablet: '150px',
                          mobile: '120px'
                        }
                      }
                    },
                    {
                      type: 'image',
                      config: {
                        src: '/images/top-empfehlung.jpg',
                        width: {
                          desktop: '200px',
                          tablet: '150px',
                          mobile: '120px'
                        },
                        margin: {
                          desktop: '20px 0 0 30px',
                          tablet: '20px 0 0 30px',
                          mobile: '20px 0 0 30px'
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        classNames: 'is-hidden-tablet',
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '20px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet',
                  containers: [
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Langfristig sparen:</b> Senke Deine Stromkostenrechnung drastisch und spare dadurch jedes Jahr <u>viel Geld</u>',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Unabhängig von steigenden Strompreisen:</b> Werde Schritt für Schritt <u>autark</u>, indem Du Deine Energie selbst gewinnst',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Clever investieren:</b> Mit einer Solaranlage legst Du Dein Geld klug an und steigerst den Wert Deines Hauses um ca. 7%',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        classNames: 'is-hidden-mobile',
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '10px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
