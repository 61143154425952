import {
  QuestionnaireInterface,
  QuestionTypes
} from '@peakconcepts/landingpage-kit/lib/components/elements/Questionnaire'

import colors from '../../content/default/colors'

const iconCss = {
  color: '#000'
}
const data: QuestionnaireInterface = {
  initStep: 1,
  mainColor: colors.questionnairyMain,
  sectionConfig: {
    container: false,
    padding: { desktop: '100px 0', tablet: '60px 0', mobile: '0' }
  },
  cardConfig: {
    defaultCss: {
      backgroundColor: 'rgb(238, 238, 238)'
    }
  },
  advantages: [
    {
      iconConfig: {
        defaultCss: iconCss
      },
      descriptionConfig: {
        text: 'Beratung durch Experten',
        defaultCss: iconCss
      }
    },
    {
      iconConfig: {
        defaultCss: iconCss
      },
      descriptionConfig: {
        text: 'Persönlicher Ansprechpartner',
        defaultCss: iconCss
      }
    },
    {
      iconConfig: {
        defaultCss: iconCss
      },
      descriptionConfig: {
        text: 'Kostenloser Solar-Check',
        defaultCss: iconCss
      }
    }
  ],
  buttons: {
    finish: {
      text: 'Jetzt Berechnung anfordern',
      subline: '100% kostenlos & unverbindlich'
    }
  },
  questions: [
    {
      step: 1,
      nextStep: 2,
      type: QuestionTypes.select,
      eventName: 'ROOF',
      cardConfig: {
        titleConfig: {
          text: 'Welche Dachform hat Ihr Haus?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Satteldach'
          },
          image: {
            src: '/icons/satteldach.svg'
          }
        },
        {
          descriptionConfig: { text: 'Flachdach' },
          image: {
            src: '/icons/flachdach.svg'
          }
        },
        {
          descriptionConfig: { text: 'Pultdach' },
          image: {
            src: '/icons/pultdach.svg'
          }
        },
        {
          descriptionConfig: { text: 'Walmdach' },
          image: {
            src: '/icons/walmdach.svg'
          }
        }
      ]
    },
    {
      step: 2,
      nextStep: 3,
      type: QuestionTypes.select,
      eventName: 'HOUSE_TYPE',
      cardConfig: {
        titleConfig: {
          text: 'Bitte wähle die Art Deines Hauses'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Einfamilienhaus'
          },
          image: {
            src: '/icons/einfamilienhaus.svg'
          }
        },
        {
          descriptionConfig: { text: 'Reihenhaus' },
          image: {
            src: '/icons/reihenhaus.svg'
          }
        },
        {
          descriptionConfig: { text: 'Doppelhaushälfte' },
          image: {
            src: '/icons/doppelhaushaelfte.svg'
          }
        },
        {
          descriptionConfig: { text: 'Mehrfamilienhaus' },
          image: {
            src: '/icons/mehrfamilienhaus.svg'
          }
        },
        {
          descriptionConfig: { text: 'Gewerbe' },
          image: {
            src: '/icons/gewerbe.svg'
          }
        }
      ]
    },
    {
      step: 3,
      type: QuestionTypes.select,
      eventName: 'OWNER',
      cardConfig: {
        titleConfig: {
          text: 'Bist Du der Eigentümer des Hauses?'
        }
      },
      answers: [
        {
          nextStep: 4,
          descriptionConfig: { text: 'Ja' },
          image: {
            src: '/icons/eigentuemer_ja.svg'
          }
        },
        {
          nextStep: 7,
          descriptionConfig: { text: 'Nein' },
          image: {
            src: '/icons/eigentuemer_nein.svg'
          }
        }
      ]
    },
    {
      step: 4,
      nextStep: 5,
      type: QuestionTypes.multipleText,
      eventName: 'ADDRESS',
      cardConfig: {
        titleConfig: {
          text: 'Wie lautet Deine Adresse?'
        },
        innerDescriptionConfig: {
          defaultCss: {
            fontSize: '16px',
            fontWeight: 'bold'
          },
          text:
            'Anhand der Adresse werden wir bei uns im System prüfen, wie hoch die Anzahl der Sonnenstunden ist und wieviel Du Dir dadurch einsparen kannst.'
        }
      },
      textConfigs: [
        {
          label: 'Straße und Hausnummer',
          placeholder: 'z.B Musterstrasse 1a',
          minLength: 2
        },
        {
          className: 'is-12-tablet is-4',
          label: 'Postleitzahl',
          placeholder: 'z.B 50667',
          regex: /[0-9]{5}/g,
          maxLength: 5,
          replaceWithRegexOnInput: /[^0-9]+/g
        },
        {
          className: 'is-12-tablet is-8',
          label: 'Ort',
          placeholder: 'Musterstadt',
          minLength: 2
        }
      ]
    },
    {
      step: 5,
      nextStep: 6,
      type: QuestionTypes.finish,
      eventName: 'CONTACT_DATA',
      config: {
        title:
          '<h4 style="text-align:center;color:#fff;font-size:1rem;">Letzter Schritt. Dann war\'s das schon!</h4>',
        description: '<b>Wer soll die Berechnung erhalten?</b>',
        color: '#37761d'
      },
      image: {
        width: {
          desktop: '100%',
          tablet: '100%',
          mobile: '150px'
        },
        src: '/icons/calculation/finish.png'
      }
    },
    {
      step: 6,
      type: QuestionTypes.formSent,
      eventName: 'FINISH',
      config: {
        title:
          '<h4 style="text-align:center;color:#fff;font-size:1rem;">Vielen Dank. Wir werden Dich in Kürze kontaktieren</h4>',
        description:
          '<p><strong>Hallo {firstname} {lastname},</strong><br/><br/>wir werden Deine Anfrage schnellstmöglich prüfen. Es wird Dich in Kürze ein Experte telefonisch oder per E-Mail kontaktieren.<br/><br/>Mit freundlichen Grüßen<br /><strong>Manfred Krause</strong></p>',
        color: '#37761d',
        contact:
          '<p style="margin-top:20px;margin-bottom:0px;"><b>Manfred Krause</b></p><small style="color:#aaa;font-size:12px;">Experte Photovoltaikanlage</small>'
      },
      image: {
        src: '/images/contact-person.png'
      }
    },
    {
      step: 7,
      eventName: 'NO_OWNER',
      type: QuestionTypes.noSelection,
      cardConfig: {
        titleConfig: {
          text: 'Es tut uns leid'
        }
      },
      config: {
        title:
          '<p style="text-align:center;text-align:left;">Für die Einspeiseanfrage beim Netzbetreiber ist der Lageplan des Hauses als Nachweis für den Grundbesitz zu erbringen. Im Lageplan wird dann die Position der neuen Photovoltaik Anlage für die Anmeldung eingezeichnet. In der Regel verfügen nur Grundstückseigentümer über einen Lageplan. Leider können wir auf dieser Grundlage keinen kostenlosen Solarstrom-Check anbieten.</p><p>Falls Du Dich verklickt hast, geh einfach zurück und setze den Solarcheck fort.</p>'
      }
    }
  ]
}

export default data
