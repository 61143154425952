import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../content/default/colors'

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)',
    position: 'relative',
    padding: '50px 30px 30px',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '60px 0 0',
    tablet: '60px 0 0',
    mobile: '60px 0 0'
  }
}
const itemTitleConfig = {
  size: 4,
  margin: {
    desktop: '20px 0 20px',
    tablet: '20px 0 0 20px',
    mobile: '20px 0 0 20px'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const itemIconContainer = {
  defaultCss: {
    position: 'absolute',
    width: '100%',
    top: '-40px',
    left: '0',
    textAlign: 'center'
  }
}

const itemIconConfig = {
  size: 8,
  margin: {
    desktop: '0 auto'
  },
  defaultCss: {
    color: '#fff',
    margin: 'auto',
    width: '80px',
    height: '80px',
    padding: '20px',
    borderRadius: '50%',
    background: colors.header
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: '#fafafa'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            defaultCss: {
              color: colors.primary
            },
            margin: {
              desktop: '0',
              tablet: '0',
              mobile: '0'
            },
            fontSize: {
              desktop: '44px',
              tablet: '36px',
              mobile: '30px'
            },
            text: 'In 3 einfachen Schritten'
          }
        },
        {
          type: 'text',
          config: {
            size: 4,
            margin: {
              desktop: '0 0 20px 0',
              tablet: '0 0 20px 0',
              mobile: '0 0 20px 0'
            },
            text: 'zur eigenen Solaranlage'
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'date_range',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: '<span>Schritt 1:</span><br/> Termin sichern'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Vereinbare jetzt ein kostenloses telefonisches Erstgespräch, um den ersten Schritt in die richtige Richtung zu gehen.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'call',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: '<span>Schritt 2:</span><br/> Analysegespräch'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Gemeinsam analysieren wir Deine aktuelle Situation und beantworten Dir alle Fragen zu einer gemeinsamen Zusammenarbeit.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'trending_up',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text:
                              '<span>Schritt 3:</span><br/> Individuelle Umsetzung'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Wir erstellen ein auf Deine Situation angepasstes Angebot. Wenn wir Dein „Go“ haben, beginnen wir zeitnah mit der Einrichtung Deiner Photovoltaikanlage.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
