import generalData from './data/general'
import colors from '../default/colors'

const header = {
  withContainer: true,
  containerConfig: {
    padding: {
      fullHd: '20px 0',
      widescreen: '20px 0',
      desktop: '20px 0',
      tablet: '20px 0',
      mobile: '10px 0'
    },
    defaultCss: {
      position: 'absolute',
      width: '100%',
      backgroundColor: 'unset'
    }
  },
  brandConfig: {
    className: 'ml-0',
    defaultCss: { justifyContent: 'center' },
    imageContainer: {
      className: 'p-0'
    },
    image: {
      src: '/images/logo.png',
      width: '130px',
      defaultCss: {
        maxHeight: 'unset !important'
      }
    }
  },
  burgerConfig: {
    hide: true
  }
}

const data = {
  config: {
    gtmID: generalData.gtmID,
    theme: {
      primary: generalData.theme.primary
    }
  },
  footer: {
    copyright: 'Copyright © 2021. Alle Rechte reserviert.',
    dark: true,
    backgroundColor: colors.footer,
    image: {
      src: '/images/logo.png',
      width: '120px'
    },
    text:
      'Hinweis: This site is not a part of the Facebook TM website or Facebook TM Inc. Additionally, this site is NOT endorsed by FacebookTM in any way.<br />FACEBOOK TM is a trademark of FACEBOOK TM, Inc.'
  },
  header,
  calendly: {
    pageSettings: {
      backgroundColor: generalData.calendly.pageSettings.backgroundColor,
      hideEventTypeDetails:
        generalData.calendly.pageSettings.hideEventTypeDetails,
      hideLandingPageDetails:
        generalData.calendly.pageSettings.hideLandingPageDetails,
      primaryColor: generalData.calendly.pageSettings.primaryColor,
      textColor: generalData.calendly.pageSettings.textColor
    },
    url: generalData.calendly.url,
    isPage: generalData.calendly.isPage
  }
}

export default data
