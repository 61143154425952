import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import QuestionnaireSection from '../../components/QuestionnaireSection'

const data: ContainerBoxInterface = {
  containers: [
    {
      defaultCss: {
        boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
        background: '#fff',
        media: [
          {
            breakpoint: 'mobile',
            css: { boxShadow: 'none' }
          }
        ]
      },
      padding: {
        fullHd: '60px 0',
        widescreen: '60px 0',
        desktop: '60px 40px',
        tablet: '40px 40px',
        mobile: '40px 30px'
      },
      containers: [
        {
          containers: [
              {
                classNames: 'columns is-multiline',
                containers: [
                  {
                    classNames: 'column is-full pl-0 pr-0',
                    containers: [
                      {
                        type: 'text',
                        config: {
                          size: 2,
                          margin: {
                            desktop: '0 0 15px',
                            tablet: '0 0 15px',
                            mobile: '0 0 15px'
                          },
                          text:
                            'Lohnt sich eine Photovoltaik-Anlage für mein Haus?'
                        }
                      },
                      {
                        type: 'text',
                        config: {
                          defaultCss: {
                            textAlign: 'center'
                          },
                          margin: {
                            desktop: '20px',
                            tablet: '20px',
                            mobile: '0'
                          },
                          text:
                            'Finde es heraus und mache einen <b>kostenlosen</b> Solarstrom-Check!<br/>Prüfe jetzt, wie viel Stromkosten Du <u>jeden Monat</u> sparen kannst:'
                        }
                      }
                    ]
                  },
                  {
                    classNames:
                      'column is-12-mobile is-10-desktop is-offset-1-desktop is-10-widescreen is-offset-1-widescreen pl-0 pr-0',
                    containers: [
                      {
                        type: 'text',
                        config: {
                          defaultCss: {
                            textAlign: 'center'
                          },
                          text: '<b>Welche Dachform hat Dein Haus?</b>'
                        }
                      },
                      {
                        type: 'component',
                        component: QuestionnaireSection
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }

export default data
