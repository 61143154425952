// Defaults
import { Content, ContainerBox, Calendly } from '@peakconcepts/landingpage-kit'
import defaultPages, { headerConfig } from './content/default/defaultPages'
import ProvenExpert from '../src/components/ProvenExpert'
import defaultSettings from './content/default/defaultSettings'
import seoHeaderData from './content/data/seoHeader'

// Data
import heroData from './content/data/hero'
import configuratorHomeData from './content/data/configuratorHome'
import openingData from './content/data/opening'
import advantagesData from './content/data/advantages'
import aboutData from './content/data/about'
import infoData from './content/data/info'
import processData from './content/data/process'
import contactData from './content/data/contact'
import faqsData from './content/data/faqs'
import generalData from './content/default/data/general'

const content: Content = {
  ...defaultSettings,
  pages: [
    {
      path: '/',
      headerEnabled: true,
      seoHeaderConfig: seoHeaderData.main,
      sections: [
        {
          component: ContainerBox,
          data: {
            ...heroData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...configuratorHomeData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...openingData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...advantagesData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...aboutData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...infoData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...processData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...contactData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...faqsData
          }
        },
        {
          component: ProvenExpert,
          data: {}
        }
      ]
    },
    {
      path: '/termin-buchen',
      headerEnabled: true,
      headerConfig: {
        ...headerConfig,
        containerConfig: {
          className: 'is-hidden-mobile',
          padding: {
            fullHd: '20px 0',
            widescreen: '20px 0',
            desktop: '20px 0',
            tablet: '20px 0',
            mobile: '10px 0'
          },
          defaultCss: {
            backgroundColor: 'unset'
          }
        }
      },
      seoHeaderConfig: seoHeaderData.main,
      component: Calendly,
      data: {
        ...generalData.calendly
      }
    },
    ...defaultPages
  ]
}
export default content
