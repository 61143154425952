import Section from '@peakconcepts/landingpage-kit/lib/components/elements/Section'
import { useEffect } from 'react'

const ProvenExpert = () => {
  const loadProvenExpert = () => {
    const script = document.getElementById('provenexpert')
    if (!script) {
      /* ProvenExpert load js */
      const tag = document.getElementsByTagName('script')[0]
      const script = document.createElement('script')
      script.id = 'provenexpert'
      script.type = 'text/javascript'
      script.src =
        'https://www.provenexpert.com/widget/landing_sonnstrom-pv-anlagen-ug-haftungsbeschraenkt.js?feedback=1&avatar=1&competence=1&style=black'
      script.async = true
      tag?.parentNode?.insertBefore(script, tag)
    }
    /* ProvenExpert load css */
    const existingCssNode = document.getElementById('provenexpert-css')
    if (!existingCssNode) {
      const head = document.getElementsByTagName('head')[0]
      const cssnode = document.createElement('link')
      cssnode.id = 'provenexpert-css'
      cssnode.type = 'text/css'
      cssnode.rel = 'stylesheet'
      cssnode.media = 'screen,print'
      cssnode.href = 'https://www.provenexpert.com/css/widget_landing.css'
      head.appendChild(cssnode)
    }
  }

  const cleanUpProvenExpert = () => {
    const script = document.getElementById('provenexpert')
    if (script) script.remove()
  }
  useEffect(() => {
    loadProvenExpert()
    return () => cleanUpProvenExpert()
  }, [])
  return (
    <>
      <Section>
        <div id="pewl"></div>
      </Section>
    </>
  )
}

export default ProvenExpert
