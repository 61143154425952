const data = {
  sectionConfig: {
    padding: {
      desktop: '40px 0',
      tablet: '40px 0',
      mobile: '40px 0'
    }
  },
  company:
    'SonnStrom GmbH<br/> Geschäftsführer: Manfred Krause<br/> Erlanger Str. 17<br/> 91083 Baiersdorf<br/><br/> Telefon: +49 (0)9131 / 811 8490<br/> E-Mail: office@sonnstrom.de',
  provider: 'STRATO',
  customCookieScriptTag: {
    id: 'CookieDeclaration',
    src:
      'https://consent.cookiebot.com/5f3c191e-c9f7-4eb8-a135-7a25ec7a32e5/cd.js',
    type: 'text/javascript',
    async: true
  }
}

export default data
