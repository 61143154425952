import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import FAQs from '../../components/FAQs'
import colors from '../default/colors'

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: colors.secondary
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Häufig gestellte Fragen',
            size: 2,
            defaultCss: {
              color: colors.primary
            }
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'component',
          component: FAQs,
          config: {
            items: [
              {
                headerConfig: {
                  textConfig: {
                    text: 'Wie viel kostet mich eine Solaranlage?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Der Preis einer Solaranlage ist immer individuell von der Größe und Ausstattung abhängig. In der Regel kostet eine PV-Anlage fürs Eigenheim ohne Speicher ca. 8.000 € bis 14.000 €, mit Speicher liegt die Investition zwischen 15.000 € und 25.000 €.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text:
                      'Wie lange dauert die Installation einer Photovoltaikanlage?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Die Montage einer Solaranlage ist für Profis wie uns ein leichtes Spiel. In der Regel benötigen wir für die Installation auf dem Dach etwa 2-3 Tage sowie einen weiteren Tag für den Elektriker. Je nach Anlagengröße und Witterung kann die Installationsdauer geringfügig abweichen.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Muss meine Photovoltaikanlage gewartet werden?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Solaranlagen haben einen sehr geringen Wartungsaufwand. Mit einem gelegentlichen Blick aufs Dach und auf die Daten im Onlineportal ist das Meiste schon erledigt. Wir empfehlen dennoch alle 5 Jahre den Blick eines Fachmannes.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Lohnt sich ein Speicher für mich?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Mit einem Stromspeicher kannst Du in den Monaten März bis September Deinen Strombedarf in der Nacht abdecken. Der Strom wird tagsüber in den Speicher geladen und Nachts wieder entladen. Dadurch machst Du Dich unabhängig von Deinem Stromversorger und bist mit einem Speicher in den Sommermonaten fast Autark. Die Größe des Speichers richtet sich nach Deinen durchschnittlichen Stromverbrauch in der Nacht. Ob sich ein Speicher für Dich lohnt und welche Speichergröße für dich geeignet ist, klären wir in einem gemeinsames Erstgespräch. Trage Dich jetzt hierzu kostenlos und unverbindlich dafür ein.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Ist mein Haus für Photovoltaik geeignet?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Die Belegung mit Photovoltaikmodulen erfordert eine freie Dachfläche. Auch mehrere kleine Flächen in unterschiedlichen Ausrichtungen und Neigungen sind nutzbar. Ab einer frei verfügbaren Fläche* von ca. 5 x 6 m lohnt sich eine PV-Anlage bereits. Mache jetzt unseren kostenlosen Solarstromcheck und finde heraus, wie viel Stromkosten Du mit einer Solaranlage auf dem Dach sparen kannst!<br/><br/><small>*ohne Dachfenster, Gauben, Kamine und Satellitenschüsseln</small>'
                  }
                }
              }
            ]
          }
        }
      ]
    }
  ]
}

export default data
