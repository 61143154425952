import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../default/colors'
import ctaButton from './ctaButton'

const descriptionConfig = {
  defaultCss: {
    color: '#fff',
    textAlign: 'left'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px 0'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-5-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/manfred-krause.jpg',
                    width: '100%'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-7-desktop',
              padding: {
                desktop: '16px 16px 0 0',
                tablet: '0 16px',
                mobile: '0'
              },
              containers: [
                {
                  defaultCss: {
                    backgroundColor: colors.header,
                    boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                  },
                  padding: {
                    desktop: '40px',
                    tablet: '40px',
                    mobile: '40px 20px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 2,
                        defaultCss: {
                          color: '#fff',
                          textAlign: 'left'
                        },
                        text:
                          'Ihr professioneller Ansprechpartner rund um erneuerbare Energien'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '150px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '20px 0 30px',
                          tablet: '20px 0 30px',
                          mobile: '20px 0 30px'
                        }
                      }
                    },

                    {
                      type: 'text',
                      config: {
                        text:
                          '„Mein Name ist Manfred Krause und ich bin Geschäftsführer von SonnStrom. <br/><br/>Wenn es um Photovoltaikanlagen geht, bist Du bei uns in den richtigen Händen: <br/><br/>Unser familiengeführtes Unternehmen besteht aus ausgebildeten Monteuren und erfahrenen Elektrikern, die bei Dir vor Ort Deine Solaranlage in Betrieb nehmen."',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: true,
                        text: ctaButton.text,
                        subline: ctaButton.subline,
                        link: ctaButton.link,
                        url: ctaButton.url,
                        margin: {
                          desktop: '50px 0 0',
                          tablet: '50px 0 0',
                          mobile: '30px 0 0'
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
