import {
  ContainerBoxInterface,
  GridTimeline,
  IGridTimeline
} from '@peakconcepts/landingpage-kit'
import colors from '../../content/default/colors'

const data: ContainerBoxInterface = {
  defaultCss: {
    background: 'url("/images/hero.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      defaultCss: {
        backgroundColor: 'rgba(232, 238, 249, .93)'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0 120px',
            widescreen: '80px 0 120px',
            desktop: '80px 40px 120px',
            tablet: '60px 40px',
            mobile: '40px 20px 20px'
          },
          containers: [
            {
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    margin: {
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    fontSize: {
                      desktop: '44px',
                      tablet: '36px',
                      mobile: '30px'
                    },
                    defaultCss: {
                      color: colors.primary
                    },
                    text: '5 gute Gründe'
                  }
                },
                {
                  type: 'text',
                  config: {
                    size: 4,
                    margin: {
                      desktop: '0 0 20px 0',
                      tablet: '0 0 20px 0',
                      mobile: '0 0 20px 0'
                    },
                    text: 'für SonnStrom'
                  }
                },
                {
                  type: 'component',
                  component: GridTimeline,
                  config: {
                    backgroundColor: 'none',
                    lineColor: colors.primary,
                    numberColor: colors.primary,
                    headColor: colors.primary,
                    bodyColor: 'white',
                    numberTextColor: 'white',
                    cardConfig: {
                      defaultCss: {
                        media: [
                          {
                            breakpoint: 'mobile',
                            css: { margin: '20px 0 !important' }
                          }
                        ]
                      }
                    },
                    headConfig: {
                      fontSize: {
                        desktop: '20px !important',
                        tablet: '20px !important',
                        mobile: '18px !important'
                      },
                      defaultCss: {
                        color: '#fff',
                        fontWeight: 'bold'
                      }
                    },
                    bodyConfig: {
                      fontSize: {
                        desktop: '18px !important',
                        tablet: '18px !important',
                        mobile: '16px !important'
                      }
                    },
                    items: [
                      {
                        title:
                          'Langjährige Erfahrung. Unzählige zufriedene Kunden',
                        content:
                          'Sicherheit pur: Eine Zusammenarbeit mit uns ist kein Experiment. Denn wir wissen genau, worauf es beim Bau von hochprofitablen Solaranlagen ankommt. Du profitierst von einer langjährigen Erfahrung in der Konzipierung und Erstellung von Solaranlagen und einem zertifizierten Solarfachberater, der auf jede Deiner Fragen die optimale Antwort hat.'
                      },
                      {
                        title: 'Alles aus einer Hand',
                        content:
                          'Du erhältst von uns die ideale Komplettlösung für Dein Eigenheim. Du bekommst nur die besten Produkte, denn wir stehen hinter jedem unserer Anbieter mit unserem Namen. Für Deine Anliegen steht zu jeder Zeit ein persönlicher Ansprechpartner zur Verfügung, der die all Deine Fragen, rund um Photovoltaik, beantworten kann. Wir übernehmen für Dich die komplette Umsetzung von der Anmeldung bis hin zur Inbetriebnahme Deiner eigenen maßgeschneiderten Solaranlage.'
                      },
                      {
                        title: 'Regionaler Partner',
                        content:
                          'Als familiäres und lokales Unternehmen sind wir immer in Deiner Nähe. Wir betreuen Dich vor Ort und legen großen Wert auf den persönlichen Kontakt zu unseren Kunden. Bei uns sind kurze Dienstwege und ein starkes Vertrauen innerhalb einer Geschäftsbeziehung sehr wichtig. Du kannst Dich sicher fühlen, weil unsere Profis Dich an die Hand nehmen und gemeinsam mit Dir den Weg in die Unabhängigkeit gehen.'
                      },
                      {
                        title: 'Zusammenarbeit mit Experten',
                        content:
                          'Mit uns hast Du stets kompetente Ansprechpartner im Bereich Photovoltaik an Deiner Seite, die auf all Deine Fragen eine Antwort haben. Sowohl unsere Elektriker und Installateure als auch unsere Kundenberater besitzen ein fundiertes Know-how, das wir Dir uneingeschränkt zur Verfügung stellen. Wir legen großen Wert auf die professionelle Umsetzung Deiner eigenen Solaranlage.'
                      },
                      {
                        title: 'Hochwertige Materialien',
                        content:
                          'Um unseren Kunden eine lange Laufzeit Ihrer Anlagen und Best-Ergebnisse in der Gewinnung von eigenem Strom durch erneuerbare Energien zu ermöglichen, verwenden wir bei der Umsetzung Ihrer Photovoltaikanlagen nur das Beste vom Besten. Gerade im Bereich Solaranlagen ist es wichtig auf Qualität zu setzen. Denn dadurch lohnt sich Deine Investition erst richtig.'
                      }
                    ]
                  } as IGridTimeline
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
