import {
  AvailableContainerBoxVariants,
  ContainerBoxInterface
} from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButtonConfigurator'
import colors from '../../content/default/colors'

const itemTitleConfig = {
  size: 2,
  defaultCss: {
    textAlign: 'left',
    color: colors.primary
  },
  margin: {
    fullHd: '0 0 30px 40px',
    widescreen: '0 0 30px 0',
    desktop: '0 0 30px 0',
    tablet: '30px 0 30px 0',
    mobile: '0 0 30px 0'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 0 20px 40px',
    widescreen: '0 0 20px 0',
    desktop: '0 0 20px 0',
    tablet: '0 0 20px 0',
    mobile: '0 0 20px 0'
  }
}

const itemHrConfig = {
  config: {
    height: 4,
    width: '150px',
    defaultCss: {
      color: 'primary'
    },
    margin: {
      fullHd: '20px 0 30px 40px',
      widescreen: '20px 0 30px 0',
      desktop: '20px 0 30px 0',
      tablet: '20px 0 30px 0',
      mobile: '20px 0 30px'
    }
  }
}

const leftItemTitleConfig = {
  size: 2,
  defaultCss: {
    textAlign: 'left',
    color: colors.primary
  },
  margin: {
    desktop: '0 0 30px 0',
    tablet: '30px 0 30px 0',
    mobile: '0 0 30px 0'
  }
}

const leftItemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 80px 20px 0',
    tablet: '0 0 20px 0',
    mobile: '0 0 20px 0'
  }
}

const leftItemHrConfig = {
  config: {
    height: 4,
    width: '150px',
    defaultCss: {
      color: 'primary'
    },
    margin: {
      fullHd: '20px 0 30px',
      widescreen: '20px 0 30px',
      desktop: '20px 0 30px',
      tablet: '20px 0 30px',
      mobile: '20px 0 30px'
    }
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      defaultCss: {
        backgroundColor: '#fafafa'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px 20px'
          },
          containers: [
            {
              type: 'text',
              config: {
                size: 2,
                margin: {
                  desktop: '0',
                  tablet: '0',
                  mobile: '0'
                },
                fontSize: {
                  desktop: '44px',
                  tablet: '36px',
                  mobile: '30px'
                },
                defaultCss: {
                  color: colors.primary
                },
                text: '5 + 1 gute Gründe'
              }
            },
            {
              type: 'text',
              config: {
                size: 4,
                margin: {
                  desktop: '0 0 20px 0',
                  tablet: '0 0 20px 0',
                  mobile: '0 0 20px 0'
                },
                text: 'für eine Photovoltaikanlage'
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '150px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 50px',
                  tablet: '30px auto 50px',
                  mobile: '30px auto 50px'
                }
              }
            },
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'image',
                      config: {
                        defaultCss: {
                          boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                        },
                        src: '/images/1.jpg',
                        width: '100%'
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text: 'Lukrative Geldanlage',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Durch eine Solaranlage investierst Du Dein Geld <u>sinnvoll</u>, anstatt es einfach nur auf der Bank liegen zu lassen und durch die Inflation nach und nach zu verlieren. Denn eine Photovoltaikanlage erzeugt Strom, den Du…',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '… selbst verbrauchen kannst und damit den <b>immer teurer werden Strom</b> nicht mehr zukaufen musst!',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '… ins Netz einspeisen kannst, um Dir dadurch ein kleines Zusatzeinkommen zu schaffen.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '<b>Die Zahlen sprechen für sich:</b> Durch die hocheffiziente Bauweise spart Dir eine Solaranlage über ihre Lebenszeit bis zu 25.000 - 30.000 € Stromkosten.',
                        ...itemDescriptionConfig
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          variant: AvailableContainerBoxVariants.ReverseWidescreen,
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'text',
                  config: {
                    text: 'Unabhängigkeit',
                    ...leftItemTitleConfig
                  }
                },
                {
                  type: 'hr',
                  ...leftItemHrConfig
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Die Strompreise haben sich in den letzten 20 Jahren <b>mehr als verdoppelt</b> und steigen jedes Jahr weiter an! ',
                    ...leftItemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Mit Solarenergie sparst Du ab dem ersten Monat und hältst Deine Stromkosten stabil.',
                    ...leftItemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Indem Du Deinen eigenen Strom produzierst, machst Du Dich <b>unabhängig</b> von steigenden Energiepreisen.',
                    ...leftItemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Du wirst Schritt für Schritt <u>autark</u> und bist den Entscheidungen Deines Stromanbieters nicht mehr schutzlos ausgeliefert.',
                    ...leftItemDescriptionConfig
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'image',
                  config: {
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                    },
                    src: '/images/2.jpg',
                    width: '100%'
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      defaultCss: {
        backgroundColor: '#fafafa'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px 20px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'image',
                      config: {
                        defaultCss: {
                          boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                        },
                        src: '/images/3.jpg',
                        width: '100%'
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text: 'So günstig wie noch nie',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'hr',
                      ...itemHrConfig
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Durch den Fortschritt der Technik und den <b>gesunkenen Anschaffungspreisen</b> ist eine Photovoltaikanlage im Jahr 2021 <u>besonders preiswert.</u>',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Staatliche Förderungen sowie günstige Baukredite erleichtern Dir den Bau der Solaranlage inklusive Speicher und machen es Dir einfach, Deine Stromrechnung zu senken.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        ...ctaButton,
                        margin: {
                          desktop: '50px 0 0',
                          tablet: '50px 0 0',
                          mobile: '30px 0 0'
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          variant: AvailableContainerBoxVariants.ReverseWidescreen,
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'text',
                  config: {
                    text: 'Attraktive Förderungen',
                    ...leftItemTitleConfig
                  }
                },
                {
                  type: 'hr',
                  ...leftItemHrConfig
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Die EEG-Novelle 2021 wird als großer Sonnenaufgang der Photovoltaik-Branche betitelt.',
                    ...leftItemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Du kannst Deine Anlage größer bauen als bisher, ohne Deinen Eigenverbrauch versteuern zu müssen.',
                    ...leftItemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Grundsätzlich profitierst Du beim Bau einer Solaranlage von <b>lukrativen Förderungen</b> vom Staat, der Dir bei der Umsetzung von Deiner eigenen Solaranlage stark unter die Arme greift.',
                    ...leftItemDescriptionConfig
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'image',
                  config: {
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                    },
                    src: '/images/4.jpg',
                    width: '100%'
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      defaultCss: {
        backgroundColor: '#fafafa'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px 20px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'image',
                      config: {
                        defaultCss: {
                          boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                        },
                        src: '/images/5.jpg',
                        width: '100%'
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text: 'Überschaubarkeit',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'hr',
                      ...itemHrConfig
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Die Kosten Deiner Investition sind <u>glasklar</u> und Dein Gewinn <u>genau kalkulierbar</u>.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Da Photovoltaik schon längere Zeit auf dem Markt ist, ist es Dir möglich Deine Rendite auf den Punkt genau zu berechnen und dadurch eine gute Übersicht über Deine Investition zu erhalten.',
                        ...itemDescriptionConfig
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          variant: AvailableContainerBoxVariants.ReverseWidescreen,
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'text',
                  config: {
                    text: 'Gut für die Umwelt',
                    ...leftItemTitleConfig
                  }
                },
                {
                  type: 'hr',
                  ...leftItemHrConfig
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Durch die Anschaffung einer Solaranlage wirst Du zum Teil der Lösung.',
                    ...leftItemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Du <u>hilfst der Natur</u> dabei, sich zu generieren, da eine Photovoltaikanlage CO²-emissionsfrei läuft. Der Verbrauch von fossilen Brennstoffen (Öl & Gas) wird durch Photovoltaikanlagen <b>enorm</b> reduziert.',
                    ...leftItemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'So kannst Du einen <u>aktiven Teil zur Verbesserung</u> der Welt bei, denn für jede Kilowattstunde selbst erzeugten Strom reduzierst Du 627g CO2 Emissionen – egal ob Du den Strom selbst nutzt oder ihn ins Netz einspeist.',
                    ...leftItemDescriptionConfig
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'image',
                  config: {
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                    },
                    src: '/images/6.jpg',
                    width: '100%'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
