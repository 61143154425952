const primaryColor = '#ffc000'

const data = {
  /** Theme */
  theme: {
    primary: primaryColor
  },
  /** TagManager */
  gtmID: 'GTM-5H8B8JL',
  /** Calendly */
  calendly: {
    pageSettings: {
      backgroundColor: 'ffffff',
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: primaryColor,
      textColor: '000000'
    },
    url: 'https://calendly.com/sonnstrom/15min',
    isPage: true
  }
}

export default data
