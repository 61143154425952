import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../default/colors'

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'contact'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Willst Du bis zu 35 % Stromkosten sparen?',
            size: 2,
            defaultCss: {
              color: colors.primary
            }
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              textAlign: 'center'
            },
            margin: {
              desktop: '0 120px',
              tablet: '0',
              mobile: '0'
            },
            text:
              'Dann klicke jetzt auf den Button und buchen Dir ein kostenloses Erstgespräch mit einem Experten von uns. Wir beantworten Dir alle Fragen und prüfen, ob sich eine Photovoltaikanlage für Dich lohnt.'
          }
        },
        {
          type: 'cta',
          classNames: 'is-12-mobile is-12-tablet',
          config: {
            dark: false,
            text: ctaButton.text,
            subline: ctaButton.subline,
            link: ctaButton.link,
            url: ctaButton.url,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
