const general = {
  title: 'Photovoltaik-Anlage » SonnStrom » deinephotovoltaikanlage.de',
  description:
    'Spare jedes Jahr bis zu 35 % Stromkosten und produziere Deinen Strom einfach selbst – mit einer Solaranlage auf Deinem Dach',
  author: '',
  publisher: '',
  revisitAfter: '7 days',
  type: 'website',
  image: 'social/share.png',
  site: '',
  twitterCard: 'summary_large_image',
  creator: ''
}

const data = {
  main: {
    ...general
  },
  imprint: {
    ...general,
    title: 'Impressum » SonnStrom'
  },
  policy: {
    ...general,
    title: 'Datenschutz » SonnStrom'
  },
  thankYou: {
    ...general,
    title: 'Danke » SonnStrom'
  }
}

export default data
