import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import configuratorData from './configurator'

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'solarstrom-check'
  },
  containers: [
    {
      nativeProps: {
        id: 'configurator'
      },
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '60px 40px',
        tablet: '0',
        mobile: '0'
      },
      ...configuratorData
    }
  ]
}

export default data
