import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../content/default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  },
  margin: {
    desktop: '0 120px 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    background: 'url("/images/hero.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      defaultCss: {
        backgroundColor: 'rgba(232, 238, 249, .93)'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px'
          },
          containers: [
            {
              defaultCss: {
                boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)',
                position: 'relative',
                backgroundColor: '#fff'
              },
              padding: {
                desktop: '50px',
                tablet: '50px',
                mobile: '40px 20px'
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    text: 'Was macht uns so besonders?',
                    defaultCss: {
                      color: colors.primary
                    },
                    size: 2
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '150px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 50px',
                      tablet: '30px auto 50px',
                      mobile: '30px auto 40px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Wir sind familiär. Intern sowie extern. Wir leben und lieben den persönlichen Kontakt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Trotz der erfolgreichen Zusammenarbeit mit unzähligen Kunden, nehmen wir uns für <u>jeden</u> unserer Klienten <u>enorm</u> viel Zeit. Denn jeder Hausbesitzer benötigt eine Solaranlage, die <b>genau zugeschnitten</b> auf seine persönliche Situation ist.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Wir haben verstanden, dass der Erfolg unserer Kunden, gleichzeitig auch unseren Erfolg ausmacht. Daher analysieren wir <u>Deine Situation</u> bis ins Detail und entwerfen daraufhin die <b>perfekte Lösung</b> für Deine persönliche Situation. Wir vertreiben nicht einfach nur Solaranlagen, wir kreieren <u>eine Komplettlösung</u> für unsere Kunden, sodass diese <b>langfristig</b> zufrieden sind.'
                  }
                },
                {
                  type: 'cta',
                  config: {
                    dark: false,
                    text: ctaButton.text,
                    subline: ctaButton.subline,
                    link: ctaButton.link,
                    url: ctaButton.url,
                    margin: {
                      desktop: '50px 0 0',
                      tablet: '50px 0 0',
                      mobile: '30px 0 0'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
