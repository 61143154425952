const data = {
  text: 'Jetzt Erstgespräch vereinbaren',
  subline: 'Hier klicken und freien Termin auswählen',
  link: '100% kostenlos & unverbindlich',
  url: {
    to: '/termin-buchen',
    internalLink: true
  }
}

export default data
